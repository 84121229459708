@import "variables.scss";
.responsiveImageContainer {
    height: 100%;
    width: 100%;
    position: relative;
}

.responsiveImage {
    position: relative;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
}
