@import "variables.scss";
.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 58px;
    @media screen and (max-width: $desktop-breakpoint) {
        gap: 28px;
    }
    color: #33541d;
    padding-bottom: calc($footer-height + 1rem);
    overflow-x: hidden;
}

.intro {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding-bottom: 1rem;
}

.description {
    font-family: var(--encode-sans-bold);
    font-size: 39px;
    @media screen and (max-width: $desktop-breakpoint) {
        font-size: 35px;
        line-height: 45px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
        font-size: 17px;
        line-height: 23px;
    }
    padding: 0rem 2rem;
    text-align: center;
    line-height: 48px;
    max-width: 1200px;
}

.details {
    font-size: 34px;
    line-height: 42px;
    padding: 0rem 3rem;
    text-align: center;
    max-width: 1000px;
    @media screen and (max-width: $desktop-breakpoint) {
        font-size: 28px;
        line-height: 36px;
    }
    @media screen and (max-width: $mobile-breakpoint) {
        font-size: 15px;
        line-height: 20px;
    }
}

.videoSection {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 381px;
    gap: 2rem;
    padding: 0rem 3rem;

    @media screen and (max-width: $navigation-under-video-breakpoint) {
        flex-flow: row wrap;
    }
}

div.video {
    position: relative;
    max-width: 800px;
    flex-shrink: 1;
}
